<template>
    <div class="scrap">
        <div class="header">
            <span @click="gouot"></span>
            <span>自动地磅称重</span>
            <span @click="islogout">退出</span>
        </div>
        <van-popup v-model="show" closeable :close-on-click-overlay="false" :style="{backgroundColor:'white',width:'80%'}" round>
            <div :style="{textAlign:'center'}">
                <van-button type="primary" :style="{width:'80%',margin:'50px 0px 15px 0px'}" @click="carIn()">车辆进厂过磅</van-button>
            </div>

            <div :style="{textAlign:'center'}">
                <van-button type="info" :style="{width:'80%',margin:'15px 0px 50px 0px'}" @click="saleOut()">销售出厂过磅</van-button>
            </div>
        </van-popup>
    </div>
</template>
<script>

</script>

<script>
import UploadImg from "../../components/upload_img";
import { Toast ,Dialog} from 'vant'
import { is_post } from "../../Http/api";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import AppVue from '../../App.vue';
    export default {
        components: {
            UploadImg,
        },
        data() {
            return {
                 show:true,//上传照片加载
            }
        },
        created(){
        },
        mounted () {
            
        },
         watch: {
            show:{
                handler(newValue, oldValue){
                     if(!newValue){
                        this.gouot()
                     }
                },
                deep: true,
            },
        },
        methods: {
            gouot() {
                this.$router.push('/guide')
            },
            carIn() {
                this.$router.push('/autoWeight/carIn')
            },
            saleOut() {
                this.$router.push('/autoWeight/saleOut')
            },
        },
    }
</script>

<style lang="scss" scoped>
.scrap {

    // padding: 10px;
    .title {
        text-align: center;
        font-size: 26px;
        margin-top: 10px;
        padding: 10px;
    }

    .scrap-content {
        margin-top: 70px;

        .scrap-content-div {
            margin-bottom: 20px;
            margin: 15px 15px;

            input: {
                border: 0px solid green;
                border-bottom-width: 1px;
            }
        }
    }

    .btn-p {
        margin: 5px;
    }
}

.van-loading {
    height: 75px !important;
    background: rgba(0, 0, 0, 0.7);
}

.accomplish {
    width: 80%;
    font-size: 26px;
    padding: 20px;
    margin-right: 0 !important;

    .accomplish-title {
        text-align: center;
    }

    .accomplish-zi {
        text-indent: 2em;
    }
}

.btn-Vin {
    margin: 10px;

}

.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;

    span {
        display: inline-block;
    }

    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}

.uploaderImg {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 1px solid #eee;
    position: relative;

    .iconz {
        position: absolute;
        top: 42%;
        left: 38%;

    }
}

.vanImage {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;

    .iconX {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>
